
.btn-m-0{
    margin: 0!important;
}
.progressbar{
    padding: 0!important;
}
.dw_disabled{
    opacity: .5;
    box-shadow: inherit!important;
        color: rgb(60, 0, 255);
}