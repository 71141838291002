/* Scroller styling */
.scroller {
    height: 1.2em;
    line-height: 1.2em;
    position: relative;
    overflow: hidden;
    width: 10em;
  }
  .scroller > span {
    position: absolute;
    top: 0;
    animation-name: slide;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 14s;
    font-weight: bold;
    
  }
  @keyframes slide {
      0%, 3% {
        transform: translateY(0);
    }

    12%, 15% {
        transform: translateY(-11.11%);
    }

    24%, 27% {
        transform: translateY(-22.22%);
    }

    36%, 39% {
        transform: translateY(-33.33%);
    }

    48%, 51% {
        transform: translateY(-44.44%);
    }

    60%, 63% {
        transform: translateY(-55.55%);
    }

    72%, 75% {
        transform: translateY(-66.66%);
    }

    84%, 87% {
        transform: translateY(-77.77%);
    }

    95% {
        transform: translateY(-88.88%);
    }
  }
  