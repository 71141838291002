// Define your breakpoints
$breakpoints: (
    mobile: 0px,
    desktop: 960px
);

// Create a mixin for media queries
@mixin media($keys...) {
    @each $key in $keys {
        @media (min-width: map-get($breakpoints, $key)) {
            @content;
        }
    }
}



.r {
    margin: auto;
        
    @include media(mobile) {
        padding-left: 25px;
        padding-right: 25px;
    }
    @include media(desktop) {
        padding: 0px;
    }
}


._hero_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #161f22;
    background-position: center;
    background-size: cover;
    z-index: -1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;

    img {
        scale: -1;
        width: 100%;
    }
}
.bar {
    background-color: black;
    width: 100%;
}
.sh {
    .shd {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 200px;
        background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0)), to(#000));
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #000);
        z-index: 1;
    }
    .flip {
        scale: -1;
    }
}
.bg {
    background-color: #161f22;
}
