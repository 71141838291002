p,
span {
    font-family: "Outfit", sans-serif
}

.button {
    background-color: #484848;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0);
    outline: 0;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 24px;
    transition: background-color .2s, transform .2s
}

.button:hover {
    background-color: #2f2f2f
}

.button:active {
    background-color: #7b7b7b;
    transform: scale(0.95);
    transition-duration: 0s;
    border-color: rgba(0, 0, 0, 0)
}

.button:focus {
    outline: none;
    border-color: rgba(0, 0, 0, 0)
}

.navbar {
    background-color: #fff
}

.navbar .navbar-burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer
}

.navbar .navbar-menu {
    @apply lg:flex-grow lg:justify-end lg:mr-10
}

.navbar .navbar-menu .navbar-item {
    @apply mt-4 lg:mt-0
}

.navbar .logo {
    filter: brightness(0)
}

footer h3 {
    @apply text-lg font-semibold mb-4
}

footer p {
    @apply mb-2
}

footer ul {
    @apply list-none
}

footer a {
    @apply text-blue-500 hover:text-blue-700
}

.card {
    box-shadow: rgba(149, 157, 165, .2) 0px 8px 24px;
    @apply max-w-md mx-auto bg-white rounded-lg overflow-hidden shadow-lg
}

.card img {
    @apply w-full h-64 object-cover
}

.card .overlay {
    @apply absolute inset-0 flex items-center justify-center
}

.card .overlay .text-center h2 {
    @apply text-2xl font-semibold text-white
}

.card .overlay .text-center p {
    @apply text-gray-300
}

.card .price {
    @apply text-gray-700 text-lg font-semibold
}

.card .button {
    @apply bg-black text-white py-2 px-4 rounded-lg
}

.card:hover .overlay p {
    @apply text-white
}

.search-component {
    display: flex;
    flex-direction: column;
    margin-left: 20px
}

.search-component .search-background {
    height: 300px;
    background-size: cover
}

.search-component .search-title {
    font-family: "Poppins", sans-serif, sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 72px;
    word-spacing: 2px;
    letter-spacing: 3px;
    color: #fff;
    margin-top: -200px
}

.search-component .search-input {
    display: flex;
    align-items: center;
    margin-top: 30px
}

.search-component .search-input input {
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: "Outfit", sans-serif
}

.search-component .search-input input::-moz-placeholder {
    color: #6a82f9
}

.search-component .search-input input::placeholder {
    color: #6a82f9
}

.search-component .search-buttons {
    display: flex;
    margin-top: 30px
}

.search-component .search-buttons .search-button {
    background-color: #6a82f9;
    color: #fff;
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 10px
}

.search-component .search-buttons .search-button:last-child {
    margin-right: 0
}

.bg-img {
    position: absolute;
    z-index: -99;
    height: 750px;
    width: 100%;
    background-size: cover;
    filter: brightness(0.7)
}

.bg {
    position: relative;
    overflow: hidden;
    height: 750px;
    width: 100%;
    z-index: -99
}

.card {
    background-color: #fff
}

.hover {
    transition: all .25s ease-out
}

.hover:hover {
    scale: 1.02;
    transition: all .35s ease
}

.title {
    font-weight: 600;
 
    font-family: "Outfit", sans-serif;
    font-style: bold
}

.account-icon {
    display: flex;
    justify-content: center;
    align-items: center
}

.account-icon svg {
    width: 25px;
    height: 25px
}

.login {
    max-width: 500px;
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    height: 50vh
}

.background-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: -20px;
    background-color: rgba(0, 0, 0, 0)
}

.form-input {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075)
}

.form-input:hover {
    border-color: #bac8d3
}

.form-input:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.form-input.is-invalid {
    border-color: #dc3545
}

.google-btn {
    width: 100%
}

.google-btn .google-icon-wrapper img {
    height: 25px
}

.or-separator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0
}

.or-separator::before,
.or-separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ced4da
}

.or-separator::before {
    margin-right: .5rem
}

.or-separator::after {
    margin-left: .5rem
}

.or-separator>span {
    padding: 0 .5rem;
    font-size: .875rem;
    color: #6c757d;
    text-transform: uppercase;
    background-color: #fff
}

.btn {
    display: flex;
    flex-direction: column;
    margin-left: 20px
}

.btn .btn-background {
    height: 300px;
    background-size: cover
}

.btn .btn-button {
    background-color: #6a82f9;
    color: #fff;
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 10px
}

.btn .btn-button:last-child {
    margin-right: 0
}

@media(max-width: 750px) {
    #list {
        justify-content: center !important
    }
    .samples img{
        min-width: 300px !important;
        min-height: 200px !important;
    }
}

.screen {
    z-index: 99;
    background-color: #000;
    opacity: .4;
    position: absolute;
    margin-top: -40px;
}

.cart {
    position: absolute;
    right: 0;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    left: 100%
}

@media(max-width: 750px) {
    .no-flex-mobile {
        display: block !important;
        justify-content: inherit !important
    }
}

.inner {
    overflow: hidden
}

.dpsh1 {
    box-shadow: rgba(17, 12, 46, .15) 0px 48px 100px 0px
}

.dpsh2 {
    box-shadow: rgba(50, 50, 93, .25) 0px 50px 100px -20px, rgba(0, 0, 0, .3) 0px 30px 60px -30px
}

.dpsh3 {
    box-shadow: rgba(50, 50, 93, .25) 0px 6px 12px -2px, rgba(0, 0, 0, .3) 0px 3px 7px -3px
}

.dpsh4 {
    box-shadow: rgba(33, 35, 38, .1) 0px 10px 10px -10px
}

.dpsh5 {
    box-shadow: rgba(0, 0, 0, .15) 0px 3px 3px 0px
}

.cartItem img {
    height: 70px
}

.circle {
    cursor: pointer;
    background-color: #6a82f9;
    border-radius: 50%;
}
.bg-main{
    background-color: #6a82f9;
    color: white;
}
.playsvg svg{
    transform: rotate(90deg);
    height: 15px;
    max-width: 100%;
}
.samples img{
    min-width: 100%;
    min-height: 300px;
}
.cartIconBTN svg{
    height: 25px;
    width: 25px;
    transform: scaleX(-1);
}
.cartIconBTN{
    transform: scaleX(-1);

}
/*# sourceMappingURL=main.min.css.map */