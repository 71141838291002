$main: #3c00ff; 
$main2: #F4D06F; 
$main3: rgb(133, 111, 244); 
// Define your breakpoints
$color2: #F4D06F; 
$breakpoints: (
    mobile: 0px,
    desktop: 960px
);

// Create a mixin for media queries
@mixin media($keys...) {
    @each $key in $keys {
        @media (min-width: map-get($breakpoints, $key)) {
            @content;
        }
    }
}

html {
    height: 100%;
    width: 100%;
    body {
        background-color: #0a0a09;

        height: 100%;
        width: 100%;
        .root {
            height: 100%;
            width: 100%;
        }
    }
}

#headerbg.show {
    opacity: 0.9;
}
#headerbg {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    background-color: #000000;
    position: fixed;
    z-index: 10;
    height: 120px;
    width: 100%;
}
#header {
    .item{
        z-index: 99;
    }
    svg{
        z-index: 100;
        min-width: 22px;
        min-height: 22px;
        color: white;
    }
    .cart-header{
        svg{
            min-width: 24px;
            min-height: 24px;
            color: white;
        }
    }
    z-index: 99;
    position: fixed;
    img {
        z-index: 99;
    }
    .logo {
        z-index: 100;
        text-decoration: none;
    }
}
@include media(mobile) {
    #header {
        height: 70px;
        img {
            height: 60px;
        }
        #headerbg {
            height: 110px;
        }
    }

    .mobile-tags{
        position: absolute;
        padding-top: 2px;
        padding-bottom: 10px;
        display: flex;
        .tagbtn{
            max-height: 15px;
        }
        .rs-btn{
            background-color: #1c1b1b;
            color: #949494;
            min-width: 15px;
            font-size: clamp(5px, 2.5vw, 11px);

        }
    }

    .track-info{
 
        transform: translateY(-5px);
    
    }
    .image-wrapper{
        min-width: 90px;
    }
}

@include media(desktop) {
    .mobile-tags{
        display: none;

    }
    #header {
        height: 120px;
        img {
            height: 85px;
        }
        #headerbg {
            height: 120px;
        }
    }
    .track-info{
      
        transform: translateY(0px);
     
    }
}

.home-videoplayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    filter: brightness(0.5);
    object-fit: cover;
    img {
        height: 100vh;
    }
}

.container {
    margin: auto;
}

.volume-control {
    @include media(mobile) {
        display: none;
    }
    @include media(desktop) {
        display: block;
    }
    .volume {
        svg {
            height: 25px;
            width: 25px;
            color: white;
        }
        @include media(mobile) {
            display: none;
        }
        @include media(desktop) {
            display: block;
        }
    }
    .volume-slider {
        @include media(mobile) {
            display: none;
        }
        @include media(desktop) {
            display: block;
        }
    }
}
.placeorder{
    @include media(mobile) {
        padding: 5%;
    }
    @include media(desktop) {
        padding: 2.4%;

    }
}
.rs-btn-primary{
    background-color: $main;
    &:hover {
        background-color: #4108ff;
    }
    &:active {
        background-color: #4108ff!important;

    }
    &:focus {
        background-color: #4108ff!important;
    }
}
.audio-player {
    width: 100%;
    position: fixed;
    background-color: #000000;
    z-index: 55;
    bottom: 0;
 
    @include media(mobile) {
        height: 125px;
    }
    @include media(desktop) {
        height: 150px;
    }
    .controls {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        height: 100%;
        
        .volume-control{
            .rs-slider-progress-bar{
                background-color: $main!important;
            }
            @include media(mobile) {
                display: none;
            }
            @include media(desktop) {
                display: flex;
            }
        }
        .data {
            padding: 2%;
            height: 100%;
            .play {
                color: white;
                height: 100px;
                width: 100px;
                svg {
                    color: white;
                    @include media(mobile) {
                        height: 40px;
                        width: 40px;
                    }
                    @include media(desktop) {
                        height: 50px;
                        width: 50px;
                    }
                   
                }
            }
            .cartbtn {
                @include media(mobile) {
                    display: block;
                    max-width: 40px;
                    height: 50px;
                    
                }
                @include media(desktop) {
                    display: block;
                    padding-right: 30px;
                    max-width: 100%;


                }
            }
        }
        display: block;
        align-items: center;
        .track-title {
            color: white;
            width: auto;
        }
        .track-meta{
            width: 90%;
                font-size: clamp(10px, 4vw, 16px);
            padding: 10px;

        }
        .progress-bar {
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            flex-grow: 1;
            height: 4px;
            background: #ddd;
            cursor: grab;
            position: relative;
            width: 100%;
            .progress {
                height: 100%;
                background: $main;
            }
           
            .circle {
                position: absolute;
                top: -8px; // half of circle's size
                left: 0; // start from the left edge
                width: 20px; // circle's size
                height: 20px; // circle's size
                background-color: white;
                border-radius: 50%;
                transform: translateX(-50%); // center the circle
                transition: transform 0.3s ease; // Add transition for smooth scaling
            
                &:hover {
                    transform: translateX(-50%) scale(1.25); // Scale on hover
                }
            }
            
        }
    }
    .icoCart{
        transform: translateX(-3px);
    }
}
.stars{
    svg{
        min-width: 20px;
        min-height: 20px;
    }
}
.tracks-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    // padding-bottom: 100px;
    .image-wrapper {
        position: relative;

        img {
            width: 100%;
            transition: 0.2s all ease-in-out;
            border-radius: 10px;
        }

        &:hover {
            img {
                filter: brightness(0.5);
            }

            .overlay {
                opacity: 1;
            }
        }
    }

    .overlay {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.3s ease;

        i.fa-play {
            color: #fff;
            font-size: 2em;
        }
    }
}

.track-wrapper-list {

    .track-info {
        z-index: 50;
        
        .dot {
            content: "";
            width: 4px;
            height: 4px;
            margin-right: 8px;
            margin-left: 8px;
            border-radius: 50%;
            background-color: var(--bs-fi-tertiary);
            flex-shrink: 0;
            display: inline-block;
        }
        font-size: clamp(6px, 3vw, 14.4px);
        .price{
            font-size: clamp(14px, 2vw, 14.4px);

        }
        .bpm{
            font-size: clamp(14px, 2vw, 14.4px);

        }
        .title{
            font-size: clamp(15px, 3vw, 17.4px);

        }

    }
    .image-wrapper {
        position: relative;
        
        &:hover img {
            filter: brightness(1)!important;
        }
    
        .overlay {
            @include media(mobile) {
                opacity: 1;
            }
            @include media(desktop) {
                opacity: 0;
                &:hover {
                    opacity: 1;
                }
            transition: opacity 0.1s ease-in-out; // Moved transition here

            }
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            svg {
                color: #ffffff;
                font-size: 2em;
            }
            
            .play {
                button {
                    border-radius: 100%;
                    height: auto;
                    background-color: white;
                    svg {
                        color: rgb(65, 65, 65);
                        @include media(mobile) {
                                    
                                    height: 30px;
                                    width: 30px;
                        }
                        @include media(desktop) {
                                    
                            height: 40px;
                            width: 40px;
                        }
                    }
                }
            }
        }
    
        img {
            @include media(mobile) {
                filter: brightness(0.7);
            }
            @include media(desktop) {
                filter: brightness(0.7);
            }
            
         transition: filter 0.2s ease-in-out; // Moved transition here
        }
     
    }
    
}
.track-wrapper {
    max-width: 500px;
    @include media(mobile) {
        padding-left: 25px;
        padding-right: 25px;
    }
    @include media(desktop) {
        padding-left: 1px;
        padding-right: 1px;
    }
 
    .play {
        button {
            border-radius: 100%;
            height: auto;
            transform: translateY(-20px);
            background-color: white;
            svg {
                color: grey;
                height: 60px;
                width: 60px;
            }
        }
    }
    .track-info {
        z-index: 50;
        margin-top: 10px;
        padding-right: 2%;
        .dot {
            content: "";
            width: 4px;
            height: 4px;
            margin-right: 8px;
            margin-left: 8px;
            border-radius: 50%;
            background-color: var(--bs-fi-tertiary);
            flex-shrink: 0;
            display: inline-block;
        }

        .price {
            color: $main2;
        }
        .bpm {
            color: #9f9f9f;
        }
        .title {
            color: white;
        }
    }
}
.cc{
    svg{
        height: 20px!important;
        width: 20px!important;
        min-height: 0px!important;
        min-width: 0px!important;
    }
}

.cartbtn {
 
    font-family: 'open sans',  sans-serif;
    span{
        transform: translateY(1px);
        font-size: 0.9rem;
    }
    background-color: $main !important;
    &:hover {
        background-color: #4912ff!important;

    }
    color: white !important;
    svg {
        min-height: 20px;
        min-width: 20px;
    }
    .rs-ripple-pond{
        display: none;
        // bugs out
    }
    &:active {
        transition: box-shadow 0s ease-in-out;
            box-shadow: 0 0 10px rgba(69, 18, 255, 0.1), 
            0 0 40px rgba(159, 132, 255, 0.2), 
            0 0 80px rgba(255, 255, 255, 0.1);
        color: rgb(60, 0, 255);
      }

        @include media(mobile) {
                                        
            min-width: 40px;
            padding: 4%;

        }
        @include media(desktop) {
                
            min-width: 90px;
            padding: 3.4%;

        }
       

}
.track-info {
    
    text-decoration: none;
}
.react-select-container .react-select__control {
    outline: none;
}
.error-input {
    border-color: red !important;
    border: solid 2px red!important;
}
.cartpanel{
    z-index: 199;
}
.cselect{
    div{
        background-color: #1c1b1b;
        z-index: 65;
        color: #cbcbcb!important;
    }
    div:nth-child(3) {
        background-color: transparent;
     
    }
    div:nth-child(3) > :first-child{
        background-color: transparent;
     
    }
    div:nth-child(3) > :first-child > :first-child {
        background-color: transparent;
     
    }
    div:nth-child(3) > :first-child > div:nth-child(2) {
        background-color: transparent;
     
    }
    background-color: #1c1b1b;
    border: none;
    box-shadow: none;
    outline: none;
    margin-top: 10px;
    font-size: 1em;
    color: #fff;
}
.citm{
    background-color: white;
    border-radius: 125px;
    max-height: 11px;
    max-width: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
    svg{
       
        color: #2c96ea!important;
        min-height: 18px!important;
        min-width: 18px!important;
    }
}
.author{
    text-decoration: none;
    color: gray;
}

.menu {
    position: fixed;
    top: 0; /* align with the top of the screen */
    left: -100%; /* start off the screen to the right */
    width: 100%; /* cover the full width of the screen */
    height: 100%; /* cover the full height of the screen */
    transition: left 0.3s ease; /* animate the right property */
    background-color: #000000;
    z-index: 120;
    .close{
        position: relative;
     
        svg{
            min-width:64px;
            min-height: 64px;
            color: white;
        }
    }
}
.menu-right.open-r {
    right: 0; /* Change to right instead of left */
}
.menu-right {
    position: fixed;
    top: 0;
    right: -100%; /* Initially hides the menu off-screen to the right */
    width: 100%;
    height: 100%;
    transition: right 0.3s ease; /* Changed transition property */
    background-color: #000;
    z-index: 120;
    .close{
        position: absolute;
        top: 18px;
        left: 2px;
        svg{
            min-width:64px!important;
            min-height: 64px!important;
            color: white;
        }
    }
}
@keyframes shine {
    0%, 100% {
      filter: brightness(0.90);
    }
    50% {
      filter: brightness(1.1);
    }
  }
  
.limitedBtn{
    animation: shine 4s infinite;

}

.menu.open {
    left: 0; /* when the menu is open, it's flush with the right edge of the screen */
}
.menu-content{
    padding: 5%;
    padding-top: 0px!important;
    height: 100%;
    position: relative;
    width: 100%;
    p{
        color: white;

    }
    .menu-list {
        margin-top: 50px;
        font-size: clamp(20px, 5vw, 60px);
        a{
            text-decoration: none;
        }
        p {
          margin-top: 20px;
          position: relative;
         
          &:hover {
            &::after {
              width: 50%;
            }
          }
      
          &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 2px;
            bottom: 0;
            left: 25%;
            background-color: #fff; // change to match your design
            transition: width .1s;
          }
        }
      }
}
.menu-header{
    height: 100px;
    width: 100%;
}
.view-buttons{
    color: white;
    padding-bottom: 2%;
    padding-top: 2%;
    font-size: clamp(8px, 4vw, 16px);

    svg{
        min-width: 15px;
        min-height: 15px;
    }
}
.view-buttons {
    padding-right: 15px;
}
.track-item{
 
    background-color: rgb(15, 15, 15);
    margin-top: 10px;
    margin-bottom: 10px;
}

.input{
    background-color: #1c1b1b;
 
    border: none;
    box-shadow: none;
    outline: none;
    margin-top: 10px;
    font-size: 1em;
    color: white;
}
.footer{
    width: 100%;
    position: absolute;
    background-color: #0a0a09;
    left: 0;
    .mail{
        font-family: 'open sans',  sans-serif;
        font-weight: 300;
        background-color: #111112;
        color: white;
    }
    .footer-link{
        color: gray;
    }
}
.search-tags{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    button{
        background-color: #1c1b1b;
        color: white;
        min-width: 75px;
    
    }
    input{
        margin-top: 0px!important;
    }
}
.tags{
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    button{
        background-color: #1c1b1b;
        color: white;
        min-width: 75px;
    
    }
    input{
        margin-top: 0px!important;
    }
}
.track-btns{
    .tags{
        margin-top: 0px;
        margin-right: 10px;
    }
    .tagbtn{
        color: gray;
    }
}
.list-view{
    padding-bottom: 100px;

}
.checkbox{
    margin-right: 5px;
    margin-left: 5px;
    height: 15px;
    width: 15px;
    position: relative;
}
.price {
    color: $main3;
}
.bpm {
    color: #9f9f9f;
    @include media(mobile) {
        line-height: 20px;
    }
}
.title {
    color: white;
}
.xpy-4{
    padding-top: 17px;
    padding-bottom: 17px;
    padding-right: 17px;
    padding-left: 17px;
}
.rs-slider-handle:focus:before{
    box-shadow: none!important;   
    border: none!important; 
}
.rs-slider-progress-bar{
    background-color: $main;
}
.rs-slider-bar
{
    background-color: #fff!important;
  
}
.rs-slider-handle{
    &:before{
        background-color: #fff!important;
    }
}
.social-link a
{
  
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d;
  transition: 0.1s ease-out;

}
.clear-input {
    padding-right: 10px;
    svg{
        height: 25px;
        width: 25px;
    }
    background-color: transparent!important;
}
.social-link svg{
  color: white;
  height: 20px;
  width: 20px;
}

.social-link a:hover
{
  background-color: #29aafe;
  transition: 0.1s ease-in;
}
.rs-slider-handle:before {
    border: none!important;
}
.links{
  display: flex;

  @include media(mobile) {
  justify-content: center;
}
  @include media(desktop) {
  justify-content: end;

  }
  align-items: center;
}
.item-link{
  display: block;
  width: auto;
  height: auto;
  color: white;
}
.item-link a{
  
  color: white;
}
p{
    color: #9f9f9f;
}
.del-button {
    background-color: transparent;
}
/* Define the styles for the scrollbar */
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  /* Define the track (background) of the scrollbar */
  ::-webkit-scrollbar-track {
    background: black; /* Make the track transparent */
  }
  
  /* Define the thumb (progress) of the scrollbar */
  ::-webkit-scrollbar-thumb {
    background: #242424; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
  }
.reviews{
    padding: 1%;
}
.mob-title-5{
    font-size: clamp(20px, 6.5vw, 35px);
    line-height: 2rem;
}
.dwcard{
    background-color: #131313;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.pt-15{
    padding-top: 4rem;
}
.bg-transparent{
    background-color: transparent!important;
}
.bg-s{
    background-color: #1c1b1b!important;
}
.rs-panel-header{
    padding: 0px!important;
}
.highlight{
    color: $color2;
}
.site-slug{
    @include media(mobile) {
        text-wrap: wrap;
    }
    @include media(desktop) {
        text-wrap: nowrap;
    }
}


.page{
    min-height: clamp(500px, 74vh, 80vh);
}
.err{
    svg{
        height: 45px;
        width: 45px;
    }
}